.singleProductWrap {
    padding: 60px 0;
    background-color: #f3f3f3;
}

.singleProductWrap .container {
    display: flex;
}

.productLeft {
    width: 50%;
    padding-right: 15px;
}
.productLeft .firstSlider {
    height: 720px;
}
.productLeft img.bigImg {
    width: 100%;
    height: 100%;
    /* height: 720px; */
    object-fit: contain;
}
.productLeft img.smallImg {
    border: 1px solid #ccc;
}
.productLeft .swiper-slide-thumb-active img.smallImg,
.productLeft .swiper-slide-active img.smallImg {
    border: 2px solid #797979;
}
.productRight {
    width: 50%;
    padding-left: 15px;
}



.proStore {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.proStore img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1.5px solid #1346af;
    object-fit: cover;
    display: block;
    margin-right: 5px;
}
.proStore h3 {
    font-size: 20px;
    font-weight: 600;
}





.productRight h1 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
}





.productPrice {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.productPrice p {
    font-size: 25px;
    margin-right: 15px;
}

.proRePrice {
    text-decoration: line-through;
    color: #a5a5a5;
}


.productDes {
    font-size: 14px;
    color: #8f8f8f;
    margin-bottom: 60px;
}



.proSize {
    margin-bottom: 60px;
}
.proSize h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
}

.proSize ul {
    display: flex;
    align-items: center;
}

.proSize ul li {
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
    border: 1px solid #ccc;
    color: #8f8f8f;
    margin-right: 15px;
    cursor: pointer;
}
.proSize ul li.active {
    color: #fff;
    background-color: #6a6a6a;
}

.disTable {
    width: 100%;
    margin-bottom: 60px;
}
.disTabHeading, .disTabRow {
    width: 100%;
    height: 35px;
    border: 1px solid #dfdfdf;
    display: flex;
}
.disTabHeading .disTabItem {
    font-size: 13px;
}
.disTabRow.active {
    background-color: #96598a;
}

.disTabItem {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-right: 1px solid #dfdfdf;
    color: #6a6a6a;
}
.disTabItem:last-child {
    border-right: none;
}

.disTabRow.active .disTabItem {
    color: #fff;
}



.proAction {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
}

.countWrap {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border: 1px solid #dfdfdf;
    border-radius: 30px;
    background-color: #fff;
    margin-right: 20px;
}

.countWrap button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 30px;
    font-weight: 200;
}

.countWrap input {
    width: 110px;
    font-size: 17px;
    text-align: center;
    border: none;
    outline: none;
}

.proAction .buyBtn {
    height: 48px;
    background-color: #1346af;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 0 60px;
    border-radius: 30px;
}

.proCat {
    display: flex;
    align-items: center;
}

.proCat span {
    color: #8f8f8f;
    margin-right: 15px;
}







@media (max-width: 1000px) {
    /* .productLeft img {
        height: 500px;
    } */
    .productLeft .firstSlider {
        height: 500px;
    }
    .productPrice {
        margin-bottom: 20px;
    }
    .productDes {
        margin-bottom: 30px;
    }
    .proSize {
        margin-bottom: 25px;
    }
    .disTable {
        margin-bottom: 25px;
    }
    .proAction {
        margin-bottom: 20px;
    }
}






@media (max-width: 700px) {
    .singleProductWrap .container {
        flex-direction: column;
        align-items: flex-start;
    }
    .productLeft {
        width: 100%;
        padding-right: 0;
        margin-bottom: 40px;
    }
    .productRight {
        width: 100%;
        padding-left: 0;
    }
}



@media (max-width: 450px) {
    .productLeft {
        margin-bottom: 20px;
    }
    /* .productLeft img {
        height: 370px;
    } */
    .productLeft .firstSlider {
        height: 370px;
    }
    .productRight h1 {
        font-size: 22px;
    }
    .productPrice p {
        font-size: 20px;
    }
    .proAction {
        flex-direction: column;
        align-items: flex-start;
    }
    .countWrap {
        margin-right: 0;
        margin-bottom: 15px;
    }
}