@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 35px;
}




.loadingWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.356);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loadingWrap img {
  width: 130px;
  height: 130px;
  display: block;
  margin-bottom: 40px;
}






@media (max-width: 550px) {
  .container {
    padding: 0 15px;
  }
}