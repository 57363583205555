.productUpWrap {
    padding: 60px 0;
}

.productUpWrap h1 {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
}


.productUpWrap form {
    width: 100%;
}

.proUpInp {
    width: 100%;
    margin-bottom: 40px;
}

.proUpInp label {
    font-size: 15px;
    color: #8f8f8f;
    display: block;
    margin-bottom: 5px;
}
.proUpInp label span {
    color: red;
}

.proUpInp input, .proUpInp select {
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border: 1px solid #dadada;
    outline: none;
}

.proUpInp .proUpSizeTog {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.proUpInp .proUpSizeTog button {
    width: 110px;
    height: 40px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    /* font-weight: 500; */
    border: 1px solid #015866;
    border-radius: 10px;
}
.proUpInp .proUpSizeTog button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.proUpInp .proUpSizeTog button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.proUpInp .proUpSizeTog button.active {
    background-color: #015866;
    color: #fff;
}

.proUpInp textarea {
    width: 100%;
    height: 150px;
    padding: 15px;
    border: 1px solid #dadada;
    outline: none;
}

.proUpInp.proUpImgInp input {
    width: fit-content;
    height: fit-content;
    border: none;
    padding: 0;
}
.proSizePre {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.proSizePre span {
    display: flex;
    align-items: center;
    margin-right: 15px;
    padding: 5px 8px;
    border: 1px solid #dadada;
    border-radius: 5px;
    font-size: 15px;
    color: #8f8f8f;
}

.proSizePre span img {
    width: 12px;
    margin-left: 8px;
    cursor: pointer;
}

.proPrevImg {
    width: 300px;
    height: 400px;
    object-fit: contain;
    display: block;
}

.proPrevMultiImgWrap {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 10px;
}
.proPrevMultiImgWrap img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}


.disCon {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.disCon input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}






.productUpWrap form button {
    width: 300px;
    height: 55px;
    border: 1px solid #dadada;
    background-color: #1346af;
    color: #fff;
    font-size: 17px;
}




.mustInp {
    color: #8f8f8f;
    display: block;
    margin-top: 10px;
}
.mustInp span {
    color: red;
    font-size: 18px;
}