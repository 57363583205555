.profileWrap {
    padding: 60px 0;
}

.profileTop {
    margin-bottom: 40px;
}
.profileTop .storeImg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
    border: 3px solid #1346af;
}

.profileTop h2 {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}


.profileInfo {
    padding: 15px 0;
    margin-bottom: 40px;
}

.profileInfoHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.profileLinkCopy {
    position: relative;
}

.copyLinkButton {
    font-size: 17px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-color: #1346af;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: .3s;
}

.copyLinkButton:hover {
    background-color: rgba(19, 70, 175, 0.9);
}

.copyTooltip {
    position: absolute;
    top: 42px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.profileInfo h3 {
    font-size: 25px;
    font-weight: 600;
}
.proInfoRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px;
    border-bottom: 1px solid #dadada;
}
.proInfoRow h4 {
    font-size: 17px;
    font-weight: 500;
    color: #8f8f8f;
}

.proInfoRow p {
    font-size: 17px;
    color: #8f8f8f;
}







.profilePro {
    padding: 15px 0;
}

.profileProHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.profileProHeading h3 {
    font-size: 25px;
    font-weight: 600;
}
.profileProHeading a {
    font-size: 17px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-color: #1346af;
    border-radius: 5px;
}
.profileProHeading a img {
    width: 25px;
    margin-right: 5px;
    filter: invert(1);
}

.singleProfilePro {
    width: 100%;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 5px -1px #ccc;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profileProLeft {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 40px;
}

.profileProLeft img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    margin-right: 25px;
}

.profileProText h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.profileProPrice {
    display: flex;
    align-items: center;
}

.profileProPrice span {
    font-size: 15px;
    margin: 3px 5px;
}
.profileProPrice span:first-child {
    text-decoration: line-through;
    color: #a5a5a5;
}




.profileProRight button {
    font-size: 16px;
    color: red;
    background-color: transparent;
    padding: 8px 10px;
    border: 1px solid red;
    outline: none;
    display: flex;
    align-items: center;
}

.profileProRight button img {
    width: 20px;
    margin-right: 8px;
}






@media (max-width: 600px) {
    .profileTop h2 {
        font-size: 30px;
    }
    .profileInfo h3 {
        font-size: 22px;
    }
    .profileProHeading h3 {
        font-size: 22px;
    }


    .profileProHeading a {
        font-size: 14px;
    }
    .profileProHeading a img {
        width: 22px;
    }

    .profileProLeft {
        padding-right: 25px;
    }
    .profileProLeft img {
        margin-right: 10px;
    }
    .profileProText h4 {
        font-size: 15px;
    }
    .profileProPrice span {
        font-size: 13px;
    }

    .profileProRight button img {
        width: 17px;
    }
    .profileProRight button {
        font-size: 14px;
    }
}





@media (max-width: 500px) {
    .copyLinkButton {
        width: 141px;
        font-size: 15px;
    }
}





@media (max-width: 400px) {
    .singleProfilePro {
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }
    .profileProLeft {
        width: 100%;
        padding-right: 0;
        margin-bottom: 25px;
        flex-direction: column;
        align-items: center;
    }
    .profileProLeft img {
        width: 70%;
        height: 250px;
        object-fit: cover;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .profileProText {
        text-align: center;
    }
}