.singleProduct {
    width: 100%;
    /* width: calc(100% / 4 - 13px); */
    /* margin-bottom: 20px; */
}

.productImg {
    width: 100%;
    height: 275px;
}

.productImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productText {
    text-align: center;
    width: 100%;
    height: 110px;
    padding: 10px 10px;
    padding-top: 20px;
}

.productText h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.priceWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priceWrap p {
    font-size: 15px;
    margin: 3px 5px;
}

.mainPrice {
    text-decoration: line-through;
    color: #a5a5a5;
}





/* @media (max-width: 1000px) {
    .singleProduct {
        width: calc(100% / 3 - 25px);
    }
}

@media (max-width: 700px) {
    .singleProduct {
        width: calc(100% / 2 - 10px);
    }
} */

@media (max-width: 550px) {
    .productImg {
        height: 230px;
    }

    .productText {
        padding-top: 10px;
    }
    .productText h3 {
        font-size: 14px;
    }
    .priceWrap p {
        font-size: 13px;
    }
}