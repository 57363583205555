.thankYou {
    height: calc(100vh - 55px);
}

.thankYou .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thankYou h1 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 15px;
}

.thankYou p {
    margin-bottom: 50px;
}
.thankYou a {
    display: block;
    padding: 8px 20px;
    border: 1.5px solid #1346af;
    background-color: transparent;
}